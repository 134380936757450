import React from 'react'
import styles from "../styles/terms.module.css"
const Terms = () => {
  return (
<div className={styles.main}>
    <h1>Terms And Conditions</h1>
    <p>Terms And Conditions</p>
    <p>Welcome to VOCS AI! These Terms and Conditions (“Terms”) govern your use of our services, including the conversion of vocals to AI voices. By accessing or using VOCS AI, you agree to comply with these Terms. Please read them carefully before using our services.</p>

    <div className={styles.termhead}>1. Contract</div>
    <p>When you use our Services you agree to all of these terms. Your use of our Services is also subject to our Cookie Policy and our Privacy Policy, which covers how we collect, use, share, and store your personal information.</p>
    <p>Here are some promises that you make to us in this Contract.</p>

    <div className={styles.termhead}>2. Change</div>
    <p>We may modify this Contract, our Privacy Policy and our Cookies Policy from time to time. If we make material changes to it, we will provide you notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. We agree that changes cannot be retroactive. If you object to any changes, you may close your account. Your continued use of our Services after we publish or send a notice about our changes to these terms means that you are consenting to the updated terms as of their effective date.</p>

    <div className={styles.termhead}>3. Age</div>
    <p>You’re eligible to enter into this Contract and you are at least our “Minimum Age.”</p>
    <p>The Services are not for use by anyone under the age of 16.</p>
    <p>To use the Services, you agree that: (1) you must be the "Minimum Age"(described below) or older; (2) you will only have one SDO account, which must be in your real name; and (3) you are not already restricted by SDO from using the Services. Creating an account with false information is a violation of our terms, including accounts registered on behalf of others or persons under the age of 16.</p>

    <div className={styles.termhead}>4. Password</div>
    <p>You will keep your password a secret</p>
    <p>You will not share an account with anyone else and will follow our rules and the law.</p>
    <p>Members are account holders. You agree to: (1) use a strong password and keep it confidential; (2) not transfer any part of your account (e.g., connections) and (3) follow the law and our list of Dos and Don’ts and Professional Community Policies. You are responsible for anything that happens through your account unless you close it or report misuse.</p>
    <p>As between you and others (including your employer), your account belongs to you. However, if the Services were purchased by another party for you to use (e.g. Recruiter seat bought by your employer), the party paying for such Service has the right to control access to and get reports on your use of such paid Service; however, they do not have rights to your personal account</p>

    <div className={styles.termhead}>5. You’re okay with us providing notices and messages to you through our website, customer service and contact information. If your contact information is out of date, you may miss out on important notices.</div>

    <div className={styles.termhead}>6. Sharing</div>
    <p>When you share information on our Services, others can see, copy and use that information.</p>

    <div className={styles.termhead}>7. Rules</div>
    <p>Please Follow the company roles otherwise restrict your account.</p>
</div>

  )
}

export default Terms